import {
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import moment from 'moment';
import React, { useContext, useEffect, useState } from "react";
import { ProductsManagementFiltersContext } from "../../../../contexts/ProductsManagementFiltersProvider";
import { formatIntNumber, formatStoresToId, prettyMoney } from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import { getProductsWithoutTurn } from "../../../../services/product-management";
import { CardBody } from "../../../../_metronic/_partials/controls";
import InfoTooltip from "../../../Common/InfoTooltip";
import Pagination from "../../../Common/Pagination";

export default function Item() {
  const { weeksInterval, category, subCategory, segment } = useContext(ProductsManagementFiltersContext);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const selectedStores = useSelectedStores();
  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseMessageError = () => {
    setAlertError(false);
  };

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const getProducts = async (page = 1) => {
    setLoading(true);
    const storesId = formatStoresToId(selectedStores).join(',');
    try {
      const { data } = await getProductsWithoutTurn(
        page,
        storesId,
        weeksInterval,
        null,
        category,
        subCategory,
        segment
      );
      cachePage(page, data.data);
      setProducts(data.data);
      setPages(data.pages);
    } catch (e) {
      setAlertError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    // if (cachedPages.hasOwnProperty(currentPage)) {
    //   setProducts(cachedPages[currentPage]);
    //   return;
    // }
    getProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    getProducts();
  }, [selectedStores, weeksInterval, category, subCategory, segment]);

  return (
    <>
      <CardBody>
        {loading && (
          <div className="loading-container">
            <CircularProgress />
          </div>
        )}
        {!loading && !products?.length && (
          <div className="empty-list">
            Nenhuma produto sem giro foi registrada neste período.
          </div>
        )}
        {!loading && !!products?.length && (
          <div className="overflow-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Item</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Marca</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Categoria</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Saldo estoque</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Valor estoque</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">QTD última compra</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Data última compra</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  products?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">{product.item}</span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">{product.brand || '-'}</span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">{product.category || '-'}</span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg badge badge-warning">
                          {formatIntNumber(product.in_stock)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {prettyMoney(product.stock_value)}
                        </span>
                        <InfoTooltip
                          text={`Custo unitário: ${prettyMoney(
                            product.unitary_cost_value
                          )}`}
                          margin="ml-2 mb-1"
                        />
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg badge badge-success">
                          {formatIntNumber(product.last_ordered_quantity)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {moment(product.last_ordered_date).format('DD/MM/YYYY')}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        )}

        {!loading && !!products?.length && (
          <div className="mt-4">
            <Pagination
              page={currentPage}
              count={pages}
              onChange={onChangePaginationHandler}
            />
          </div>
        )}
      </CardBody>
      <Snackbar
        open={alertError}
        autoHideDuration={2500}
        onClose={handleCloseMessageError}
      >
        <Alert onClose={handleCloseMessageError} severity="error">
          Erro ao carregar dados
        </Alert>
      </Snackbar>
    </>
  );
}
