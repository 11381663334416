import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useSelectedStores } from "../../../../hooks/stores";
import Pagination from "../../../Common/Pagination";
import {
  formatStoresToId,
  prettyMoney,
  prettyPercentage
} from "../../../../helpers";
import { getAbcAnalysis } from "../../../../services/abc";
import InfoTooltip from "../../../Common/InfoTooltip";
import moment from 'moment';
import { getNeedToBuyProducts } from "../../../../services/product-management";
import Alert from "@material-ui/lab/Alert";
import { ProductsManagementFiltersContext } from "../../../../contexts/ProductsManagementFiltersProvider";
import ProductsList from "../ProductsList";

export default function BrandsProducts({ brand, curve, onlyProductsWithBuyNecessity }) {
  const { weeksInterval, category, subCategory, segment } = useContext(ProductsManagementFiltersContext);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedStores = useSelectedStores();
  const [alertError, setAlertError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const fetchProducts = async (page = 1) => {
    setLoading(true);

    const storesId = formatStoresToId(selectedStores);

    try {
      const { data } = await getNeedToBuyProducts(
        page,
        storesId,
        weeksInterval,
        curve,
        'totalValueData',
        brand,
        onlyProductsWithBuyNecessity ? 1 : 0,
        category,
        subCategory,
        segment
      );
      cachePage(page, data.data);
      setProducts(data.data);
      setPages(data.pages);
    } catch (e) {
      setAlertError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    // if (cachedPages.hasOwnProperty(currentPage)) {
    //   setProducts(cachedPages[currentPage]);
    //   return;
    // }

    fetchProducts(currentPage);
  }, [currentPage]);

  const handleCloseMessageError = () => {
    setAlertError(false);
  };

  return (
    <div className="p-10 mb-10 rounded" style={{ backgroundColor: "#f7f7f7" }}>
      <ProductsList
        loading={loading}
        currentPage={currentPage}
        pages={pages}
        products={products}
        onChangePaginationHandler={onChangePaginationHandler}
        showBrands={false}
      />

      <Snackbar
        open={alertError}
        autoHideDuration={2500}
        onClose={handleCloseMessageError}
      >
        <Alert onClose={handleCloseMessageError} severity="error">
          Erro ao carregar dados
        </Alert>
      </Snackbar>
    </div>
  );
}
