import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ProductsManagementFiltersContext } from "../../../../../contexts/ProductsManagementFiltersProvider";
import {
  formatIntNumber,
  formatStoresToId,
  prettyMoney
} from "../../../../../helpers";
import { useSelectedStores } from "../../../../../hooks/stores";
import { getProductsWithoutTurn } from "../../../../../services/product-management";
import InfoTooltip from "../../../../Common/InfoTooltip";
import Pagination from "../../../../Common/Pagination";

export default function BrandProductsWithoutTurn({ brand }) {
  const { weeksInterval, category, subCategory, segment } = useContext(ProductsManagementFiltersContext);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedStores = useSelectedStores();

  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const fetchProducts = async (page = 1) => {
    setLoading(true);

    let stores = null;

    if (selectedStores.length) {
      stores = formatStoresToId(selectedStores).join(",");
    }

    try {
      const { data } = await getProductsWithoutTurn(page, stores, weeksInterval, brand, category, subCategory, segment);
      cachePage(page, data.data);
      setProducts(data.data);
      setPages(data.pages);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    // if (cachedPages.hasOwnProperty(currentPage)) {
    //   setProducts(cachedPages[currentPage]);
    //   return;
    // }

    fetchProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCachedPages({});
    setCurrentPage(1);
  }, [selectedStores, weeksInterval, category, subCategory, segment]);

  return (
    <div className="p-10 mb-10 rounded" style={{ backgroundColor: "#f7f7f7" }}>
      {loading && (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}

      {!loading && !products?.length && (
        <div className="empty-list">
          Nenhum produto encontrado.
        </div>
      )}

      {!loading && !!products?.length && (
        <>
          <div className="overflow-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Código</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Item</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Categoria</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Estoque QTD</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Estoque R$</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  products?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">{product.sku || '-'}</span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">{product.item}</span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.category || '-'}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg badge badge-warning">
                          {formatIntNumber(product.in_stock)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {prettyMoney(product.stock_value)}
                        </span>
                        <InfoTooltip
                          text={`Custo unitário: ${prettyMoney(
                            product.unitary_cost_value
                          )}`}
                          margin="ml-2 mb-1"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}

      {!loading && !!products?.length && (
        <div className="mt-10">
          <Pagination
            page={currentPage}
            count={pages}
            onChange={onChangePaginationHandler}
          />
        </div>
      )}
    </div>
  );
}
