import {
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useContext, useEffect, useState } from "react";
import { ProductsManagementFiltersContext } from "../../../../contexts/ProductsManagementFiltersProvider";
import {
  decimalMoney,
  formatIntNumber,
  formatStoresToId,
  prettyPercentage
} from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import { getLowTurnProducts } from "../../../../services/product-management";
import { CardBody } from "../../../../_metronic/_partials/controls";
import ComparisonTooltip from "../../../Common/ComparisonTooltip";
import InfoTooltip from "../../../Common/InfoTooltip";
import Pagination from "../../../Common/Pagination";

export default function Item() {
  const { weeksInterval, category, subCategory, segment } = useContext(
    ProductsManagementFiltersContext
  );
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const selectedStores = useSelectedStores();
  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseMessageError = () => {
    setAlertError(false);
  };

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const getProducts = async (page = 1) => {
    setLoading(true);
    const storesId = formatStoresToId(selectedStores);
    try {
      const { data } = await getLowTurnProducts(
        page,
        storesId,
        weeksInterval,
        null,
        category,
        subCategory,
        segment
      );

      cachePage(page, data.data);
      setProducts(data.data);
      setPages(data.pages);
    } catch (e) {
      setAlertError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    // if (cachedPages.hasOwnProperty(currentPage)) {
    //   setProducts(cachedPages[currentPage]);
    //   return;
    // }
    getProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    getProducts();
  }, [selectedStores, weeksInterval, category, subCategory, segment]);

  const getSubcategories = subcategories => {
    if (typeof subcategories === "string") {
      return JSON.parse(subcategories);
    }

    return subcategories;
  };

  return (
    <>
      <CardBody>
        {loading && (
          <div className="loading-container">
            <CircularProgress />
          </div>
        )}
        {!loading && !products?.length && (
          <div className="empty-list">
            Nenhum produto com baixo giro foi encontrado.
          </div>
        )}
        {!loading && !!products?.length && (
          <div className="overflow-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Código</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Item</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Marca</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Categoria</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Venda QTD</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Venda R$</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="font-size-lg">CMV R$</span>
                      <InfoTooltip
                        text="Venda R$ a CMV (Custo da Mercadoria Vendida)"
                        margin="mb-1 ml-2"
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Lucro Bruto R$</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Estoque QTD</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Estoque R$</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Curva</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">MB%</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6 }}>
                    <span className="font-size-lg">Mark Up</span>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <div className="d-flex align-items-center">
                      <span className="font-size-lg">VMM</span>
                      <InfoTooltip
                        text="Venda Média Mensal no período analisado"
                        margin="ml-2 mb-1"
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: 6, minWidth: 100 }}>
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="font-size-lg">PSI Index</span>
                      <InfoTooltip
                        text="PSI Index: fator que indica se VMM (Venda Média Mensal) do período analisado é maior ou menor que a venda média calculada baseada nas vendas da última semana."
                        margin="mb-1 ml-2"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  products?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.sku || "-"}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">{product.name}</span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.brand || "-"}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.category || "-"}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {formatIntNumber(product.total_sold)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {decimalMoney(product.total_value_without_discounts)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {decimalMoney(product.cmv)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {decimalMoney(product.gross_profit)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg badge badge-warning">
                          {formatIntNumber(product.in_stock)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {decimalMoney(product.stock_value)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">C</span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                        {product.gross_margin && +product.gross_margin > 0
                            ? parseFloat(product.gross_margin).toFixed(2) + '%'
                            : "-"}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg">
                          {product.mark_up && +product.mark_up > 0
                            ? parseFloat(product.mark_up).toFixed(2)
                            : "-"}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="font-size-lg badge badge-success">
                          {Math.round(parseFloat(product.vmm)).toFixed(0)}
                        </span>
                      </TableCell>
                      <TableCell style={{ border: 0, padding: 6 }}>
                        <span className="d-flex align-items-center">
                          <ComparisonTooltip
                            a={parseFloat(product.vmm)}
                            b={parseFloat((product.vms / 8) * 30)}
                            texts={{
                              same: "Se manteve o mesmo",
                              obs: product.vms == 0 ? "VMM' é 0" : false
                            }}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        )}

        {!loading && !!products?.length && (
          <div className="mt-4">
            <Pagination
              page={currentPage}
              count={pages}
              onChange={onChangePaginationHandler}
            />
          </div>
        )}
      </CardBody>
      <Snackbar
        open={alertError}
        autoHideDuration={2500}
        onClose={handleCloseMessageError}
      >
        <Alert onClose={handleCloseMessageError} severity="error">
          Erro ao carregar dados
        </Alert>
      </Snackbar>
    </>
  );
}
