import {
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ProductsManagementFiltersContext } from "../../../../contexts/ProductsManagementFiltersProvider";
import { formatStoresToId, prettyMoney } from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import { getBrandsAbcAnalysis } from "../../../../services/abc";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import InfoTooltip from "../../../Common/InfoTooltip";
import Pagination from "../../../Common/Pagination";
import BrandsProducts from "../BrandsProducts";

function TableHeadCell({
                         id,
                         label,
                         orderBy,
                         tooltip,
                         orderDirection,
                         handleSort,
                         disableOrder = false
                       }) {
  return [
    <TableCell
      style={{ border: 0, padding: 6 }}
      sortDirection={orderBy === id ? orderDirection : false}
    >
      {disableOrder && (
        <>
          <span className="font-size-lg">{label}</span>
          {!!tooltip && <InfoTooltip text={tooltip} margin="ml-2" />}
        </>
      )}

      {!disableOrder && (
        <TableSortLabel
          active={orderBy === id}
          direction={orderBy === id ? orderDirection : "asc"}
          onClick={() => {
            if (!disableOrder) {
              handleSort(id);
            }
          }}
        >
          <span className="font-size-lg">{label}</span>
          {!!tooltip && <InfoTooltip text={tooltip} margin="ml-2" />}
        </TableSortLabel>
      )}
    </TableCell>
  ];
}

function Row({ brand, curve, onlyProductsWithBuyNecessity }) {
  const { weeksInterval } = useContext(ProductsManagementFiltersContext);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <TableRow>
        <TableCell style={{ border: 0, padding: 6 }}>
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">{brand.brand || "-"}</span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">{prettyMoney(brand.value)}</span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">{brand.sold_quantity}</span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          {brand.cost > 0 ? (
            <span className="font-size-lg">{prettyMoney(brand.cost)}</span>
          ) : (
            <span className="font-size-lg">-</span>
          )}
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          {brand.profit > 0 ? (
            <span className="font-size-lg">{prettyMoney(brand.profit)}</span>
          ) : (
            <span className="font-size-lg">-</span>
          )}
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {parseFloat(brand.gross_margin).toFixed(2)}
          </span>
        </TableCell>
        <TableCell style={{ border: 0, padding: 6 }}>
          <span className="font-size-lg">
            {brand.mark_up && +brand.mark_up > 0
              ? parseFloat(brand.mark_up).toFixed(2)
              : "-"}
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0, padding: 6 }}
          colSpan={8}
        >
          <Collapse
            in={open}
            classes={{
              hidden: "hiddenCollapse"
            }}
          >
            <BrandsProducts
              brand={brand.brand || "-"}
              curve={curve}
              onlyProductsWithBuyNecessity={onlyProductsWithBuyNecessity}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function toggleDirection(direction) {
  if (direction === "asc") {
    return "desc";
  } else if (direction === "desc") {
    return "asc";
  }
}

export default function Brands({ curve, onlyProductsWithBuyNecessity }) {
  const { weeksInterval, category, subCategory, segment } = useContext(
    ProductsManagementFiltersContext
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState(null);
  const [brandsTotalValue, setBrandsTotalValue] = useState(null);
  const [brandsTotal, setBrandsTotal] = useState(null);
  const [pages, setPages] = useState(0);
  const selectedStores = useSelectedStores();
  const [orderBy, setOrderBy] = useState("brand");
  const [orderDirection, setOrderDirection] = useState("desc");

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const getBrands = async (page = 1) => {
    setLoading(true);

    const params = {
      weeks: weeksInterval,
      order_by: orderBy,
      order: orderDirection,
      group_by: "curve",
      category,
      subcategory: subCategory,
      segment,
      only_with_buy_necessity: onlyProductsWithBuyNecessity ? 1 : 0
    };

    if (selectedStores.length) {
      params.stores = formatStoresToId(selectedStores).join(",");
    }

    try {
      const { data } = await getBrandsAbcAnalysis(page, params);

      cachePage(page, data.data[curve]);
      setBrands(data.data[curve]);
      setPages(data.pages);
    } catch (e) {}

    setLoading(false);
  };

  useEffect(() => {
    if (brands?.length) {
      // if (cachedPages.hasOwnProperty(currentPage)) {
      //   setBrands(cachedPages[currentPage]);
      //   return;
      // }
      getBrands(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getBrands(1);
  }, [
    curve,
    selectedStores,
    weeksInterval,
    orderBy,
    orderDirection,
    category,
    subCategory,
    segment,
    onlyProductsWithBuyNecessity
  ]);

  const handleSort = column => {
    const newOrderDirection =
      column === orderBy ? toggleDirection(orderDirection) : "desc";

    if (column !== orderBy) {
      setOrderBy(column);
    }

    setOrderDirection(newOrderDirection);
  };

  const headColumns = [
    { id: "brand", label: "Marca" },
    { id: "value", label: "Venda R$" },
    { id: "sold_quantity", label: "Venda QTD" },
    {
      id: "cost",
      label: "CMV R$",
      tooltip: "Venda R$ a CMV (Custo da Mercadoria Vendida)"
    },
    { id: "profit", label: "Lucro Bruto R$" },
    { id: "gross_margin", label: "MB%", tooltip: "Margem Bruta %" },
    { id: "mark_up", label: "Mark Up" }
  ];

  return (
    <>
      <Card>
        <CardBody
          className={
            !loading &&
            !brands?.length &&
            "d-flex justify-content-center align-items-center"
          }
          style={{ height: "100%" }}
        >
          {loading && (
            <div className="loading-container">
              <CircularProgress />
            </div>
          )}

          {!loading && !brands?.length && (
            <div className="empty-list">Nenhum dado encontrado.</div>
          )}

          {!loading && !!brands?.length && (
            <div className="overflow-auto">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: 0, padding: 6 }} />
                    {headColumns.map(column => (
                      <TableHeadCell
                        key={column.id}
                        id={column.id}
                        label={column.label}
                        tooltip={column.tooltip}
                        handleSort={handleSort}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                        disableOrder={column.disableOrder}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    brands?.map(brand => (
                      <Row
                        key={brand.brand || "-"}
                        brand={brand}
                        curve={curve}
                        onlyProductsWithBuyNecessity={
                          onlyProductsWithBuyNecessity
                        }
                      />
                    ))}
                </TableBody>
              </Table>
            </div>
          )}

          {!loading && !!brands?.length && (
            <div className="mt-5">
              <Pagination
                page={currentPage}
                count={pages}
                onChange={onChangePaginationHandler}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
