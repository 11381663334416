import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  formatStoresToId,
  prettyMoney,
  prettyPercentage
} from "../../../../../helpers";
import { useSelectedStores } from "../../../../../hooks/stores";
import InfoTooltip from "../../../../Common/InfoTooltip";
import Pagination from "../../../../Common/Pagination";

export default function Products({ productsFetcher, detailVision }) {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedStores = useSelectedStores();

  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (page, orders) => {
    setCachedPages({ ...cachedPages, [page]: orders });
  };

  const fetchProducts = async (page = 1) => {
    setLoading(true);
    const params = {
    };
    
      params.detailVision = detailVision;

    if (selectedStores.length) {
      params.stores = formatStoresToId(selectedStores).join(",");
    }

    try {
      const { data } = await productsFetcher(page, params);
      cachePage(page, data.data);
      setProducts(data.data);
      setPages(data.pages);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    // if (cachedPages.hasOwnProperty(currentPage)) {
    //   setProducts(cachedPages[currentPage]);
    //   return;
    // }

    fetchProducts(currentPage);
  }, [currentPage]);

  return (
    <>
      {loading && (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}

      {!loading && !products?.length && (
        <div className="empty-list">Nenhum produto encontrado.</div>
      )}

      {!loading && !!products?.length && (
        <div className="overflow-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg">Item</span>
                </TableCell>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg">Marca</span>
                </TableCell>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg">Venda QTD</span>
                </TableCell>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg">Venda R$</span>
                </TableCell>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg">Venda CMV</span>
                </TableCell>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg">Lucro Bruto</span>
                </TableCell>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg mr-2">MB</span>
                  <InfoTooltip text="Margem Bruta (%)" />
                </TableCell>
                <TableCell
                  style={{ border: 0, padding: 6, backgroundColor: "#f7f7f7" }}
                >
                  <span className="font-size-lg mr-2">Mark Up</span>
                  <InfoTooltip text="Mark Up bruto" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                products?.map(product => (
                  <TableRow key={product.id}>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">{product.name}</span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">
                        {product.brand || "-"}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">{product.total_sold}</span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">
                        {prettyMoney(product.total_value_without_discounts)}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">
                        {prettyMoney(product.cmv)}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">
                        {prettyMoney(product.gross_profit)}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">
                        {prettyPercentage(
                          (product.total_value_without_discounts -
                            product.cmv) /
                            product.total_value_without_discounts
                        )}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: 0,
                        padding: 6,
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <span className="font-size-lg">
                        {product.mark_up && +product.mark_up > 0
                          ? parseFloat(product.mark_up).toFixed(2)
                          : "-"}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}
      {!loading && !!products?.length && (
        <div className="mt-10">
          <Pagination
            page={currentPage}
            count={pages}
            onChange={onChangePaginationHandler}
          />
        </div>
      )}
    </>
  );
}
